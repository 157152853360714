import { render, staticRenderFns } from "./长电4G-MF1.vue?vue&type=template&id=31263a7e&scoped=true&"
import script from "./长电4G-MF1.vue?vue&type=script&lang=js&"
export * from "./长电4G-MF1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31263a7e",
  null
  
)

export default component.exports